<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-spin-clockwise">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="logoutdiv">
  <button class="logout-btn" *ngIf="loginDisplay" (click)="logout()">Logout</button>
  <!-- Light and Dark mode switch -->
  <label class="switch">
    <input type="checkbox" (change)="toggleTheme()" [checked]="isDarkMode">
    <span class="slider">
    </span>
  </label>
  <!-- <img src="../assets/SWAPA_Core_RGB_Blue_transp.png" alt="Contract AI Logo" class="logo"> -->
  <img 
  [src]="isDarkMode ? '../assets/SWAPA_Core_RGB_Wht_transp.png' : '../assets/SWAPA_Core_RGB_Blue_transp.png'" 
  alt="Contract AI Logo" 
  class="logo">
</div>
<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>