import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalInterceptor, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { HomeComponent } from '../home/home.component';
import { environment } from '../../environments/environment';
import { JwtInterceptor } from '../../interceptor/jwt.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { NewlineToBrPipe } from './pipes/newline-to-br.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Original code
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NewlineToBrPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({type: 'ball-spin-clockwise'}),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId, 
          authority: environment.authorityURI,
          redirectUri: environment.replyUrl,
          knownAuthorities: [environment.knownAuthority], 
        },
        // Refresh error trial
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage,
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [`${environment.scopeUri}/${environment.scope}`]
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.apiOrigin, [`${environment.scopeUri}/${environment.scope}`]]
        ])
      })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }
],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
