export const environment = {
    production: false,
    clientId : 'abeab0ce-4e8b-42f7-955b-fe7cd1bbfb99',
    scope : 'Read',
    scopeUri : 'https://swapapilotsdev.onmicrosoft.com/ContractAI',
    replyUrl : 'https://contractai-dev.swapa.org',
    apiOrigin : 'https://contractaiapi-dev.swapa.org',
    authorityURI : 'https://logindev.swapa.org/swapapilotsdev.onmicrosoft.com/B2C_1A_SEAMLESSMIGRATION_SIGNIN',
    knownAuthority : 'logindev.swapa.org'
};
